import logo from './logo.svg';
import './App.css';
import Fondo from'./img/backgroud.mp4';
import Fondo1 from'./img/backgroud1.mp4';
import Logo from'./img/recurso2.png';
import Play from'./img/play.png';
import Iconi from'./icons/iconsi.svg';
import Iconf from'./icons/iconsf.svg';
import Icony from'./icons/iconsy.svg';
import Icont from'./icons/iconst.svg';
import Timer from'./components/timer/timer';


function App() {
  return (
    <div className="c">

          <div className='main__iconsContainer'>
            <div className='main__iconsContainer__icons'><a target="_blank" href='https://www.youtube.com/@jisoOfficial'><img  alt='youtube'   src={Icony}/></a></div>
            <div className='main__iconsContainer__icons'><a target="_blank" href='https://www.instagram.com/jiso0fficial/'><img  alt='instagram'   src={Iconi}/></a></div>
            <div className='main__iconsContainer__icons'><a target="_blank" href='https://www.facebook.com/share/w4rTRNqNU9daEKAZ/'><img  alt='facebook'   src={Iconf}/></a></div>
            <div className='main__iconsContainer__icons'><a target="_blank" href='https://www.tiktok.com/@jiso0fficial?lang=es'><img  alt='tiktok'   src={Icont}/></a></div>         
            
          </div>
          <div className='main__containerLogo'><img src={Logo}/></div>
          <Timer duration ={2 * 24* 60 * 60 *1000}/>

          <div className='main__conteinerMedio'><h2>JISO'S HOUSE EP</h2></div>
      <div className='main__VideoContainer'>
        <video className="main__VideoContainer__video" loop autoPlay muted>
          <source src={Fondo1} type="video/mp4" />
        </video> 
      </div>
      <div className='main__Container__play' ><button ><a href='https://beacons.ai/jiso0fficial?fbclid=PAY2xjawGo07JleHRuA2FlbQIxMQABpoocX3kluq-buq97TKhr4rh889ifKNNV7ADnIf0gUv3a46qYJPRMa-zwfg_aem_ETMzr0ZiwNOEQcbur6ulfA'><img src={Play}></img> <h5 className='main__Container__play__texto'>PLAY A MI MÚSICA</h5></a></button></div>


       
   </div>
  );
}

export default App;
