
import React, { useEffect, useState } from 'react';
import './timer.css';



const Timer = () => {

const [data, setData] = useState(0);
const [dia, setDia] = useState(0);
const [hora, setHora] = useState(0);
const [minutos,setMinutos] = useState(0);
const [segundo, setSegundos] = useState(0);

useEffect(() => {
setInterval(() => {
  setData(new Date())
  
},1000);

},);
  
useEffect(() => {
  data >1 && setDia(data.getDate());
  data >1 && setHora(data.getHours());
  data >1 && setMinutos(data.getMinutes())

  data >1 && setSegundos(data.getSeconds())



  
}, [data]);



  return (
    <div className='timerContainer'>
      <div className='timerContainer__items'>
        <div className='timerContainer__items__fecha'>{data && 50
        -dia}:</div>
        <div><h6 className='timerContainer__items__texto'>Dia </h6></div>
        </div>
      <div className='timerContainer__items'>
       <div className='timerContainer__items__fecha'>{24-hora}:</div>
       <div><h6 className='timerContainer__items__texto'>Hr</h6></div>
        </div>
      <div className='timerContainer__items' >
        <div className='timerContainer__items__fecha'>{60-minutos}:</div>
        <div><h6 className='timerContainer__items__texto'>Min</h6></div>
      </div>
      <div className='timerContainer__items'>
      <div className='timerContainer__items__fecha'>{60-segundo}</div>
      <div><h6 className='timerContainer__items__texto'>Seg</h6></div>
        
      </div>

    </div>
  )
};

export default Timer

